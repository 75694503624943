.ant-radio-checked .ant-radio-inner:after{
  background-color: #2C9044;
  border-color: black ;
}

.ant-select-selection {
  background-color: green;
}

.ant-select-single .ant-select-selector {
  border-radius: 8px !important;
}

.ant-form label {
  padding-bottom: 10px;

}

/* .ant-form-item-has-error .ant-input, .ant-form-item-has-error .ant-input-affix-wrapper, .ant-form-item-has-error .ant-input:hover, .ant-form-item-has-error .ant-input-affix-wrapper:hover {
  background-color: #fff;
  border-color: #B20000;
} */

.ant-form-item-has-error .ant-select:not(.ant-select-borderless) .ant-select-selector {
  border-color: #B20000 !important;
}

.ant-form-item-has-error .ant-select-selection-placeholder {
  color: #B20000;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #40356f1A !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: white !important;
  border-color: #40356f !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #fff  !important;
}
.ant-radio-group-solid:hover .ant-radio-button-wrapper:hover {
  color: rgba(0, 0, 0, 0.65)  !important;
}

.ant-radio-wrapper:hover .ant-radio, .ant-radio:hover .ant-radio-inner, .ant-radio-input:focus + .ant-radio-inner {
  border-color: gray;
}

.icon-option:hover{
  background-color: #40356f1A;
}

.ant-radio-group{
  width: 100%;
}

.contact-form .ant-form-item-label > label {
  color: #4E647B;
  padding-bottom: 0px;
}

.ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
  margin-right: 0;
}

